<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
	data() {
		return {
			listed: false,
			users: [],
			fields: {
				id: { name: "id" },
				code: { name: "Número" },
				name: { name: "Nombre" },
				// last_name: { name: "Apellidos" },
				email: { name: "Email" },
				is_active: { name: "Activo" },
				is_full_time: { name: "Tiempo Completo" },
				work_position: { name: "Puesto" },
			},
			filters: {
				territory_id: {
					name: "Territorio",
					options: {},
					selected: null,
				},
				region_id: {
					name: "Región",
					options: {},
					selected: null,
				},
				agency_id: {
					name: "Agencia",
					options: {},
					selected: null,
				},
			},
		};
	},
	created() {
		this.loadFilter();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			//READ_USERS
			readUsers: "getUsers/readUsers",

			//READ_FILTERS
			readTerritories: "getTerritories/readTerritories",
			readRegions: "regionsStore/readRegions",
			readAgencies: "agenciesStore/readAgencies",

			//READ_ERRORS
			getUsersError: "getUsers/readError",
			deleteUserError: "deleteUser/readError",
			territoriesError: "getTerritories/readError",
			regionsError: "regionsStore/readError",
			agenciesError: "agenciesStore/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//SET_USER_ID
			setUserId: "getUsers/SET_ID",
			setDeleteId: "deleteUser/SET_ID",

			//SET_FILTERS_ID
			setRegionId: "regionsStore/SET_ID",
			setRegionFilter: "regionsStore/SET_FILTER",
			setAgencyId: "agenciesStore/SET_ID",
			setAgencyFilter: "agenciesStore/SET_FILTER",
			setUsersFilter: "getUsers/SET_FILTER",
			setUsersPagination: "getUsers/SET_PAGINATION",

			//SET_ERRORS
			setUsersError: "getUsers/SET_ERROR",
			setDeleteUserError: "deleteUser/SET_ERROR",
			setTerritoriesError: "getTerritories/SET_ERROR",
			setRegionsError: "regionsStore/SET_ERROR",
			setAgenciesError: "agenciesStore/SET_ERROR",
		}),
		...mapActions({
			//USERS
			getUsers: "getUsers/getUsers",
			deleteUser: "deleteUser/deleteUser",

			//GET_BY_FILTERS
			getTerritories: "getTerritories/getTerritories",
			regionActions: "regionsStore/regionActions",
			agencyActions: "agenciesStore/agencyActions",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadUsers() {
			this.clearErrors();
			this.setLoading(true);
			this.setUserId(null);
			this.setUsersPagination(null);
			this.setUsersFilter({
				name: "agency",
				id: this.filters.agency_id.selected,
			});
			this.getUsers()
				.then((response) => {
					if (response) {
						this.users = this.readUsers;
					}
				})
				.finally(() => {
					this.listed = true;
					this.setLoading(false);
				});
		},
		loadFilter() {
			this.listed = false;
			this.setLoading(true);
			this.clearErrors();
			this.clearFilter("territory_id");
			this.clearFilter("region_id");
			this.clearFilter("agency_id");
			this.getTerritories()
				.then((response) => {
					if (response) {
						if (this.readTerritories.length > 0) {
							for (let t in this.readTerritories) {
								this.filters.territory_id.options[
									this.readTerritories[t].id
								] = this.readTerritories[t].name;
							}
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		refreshFilter(selected) {
			this.setLoading(true);
			this.clearErrors();
			this.listed = false;
			this.users = [];

			if (selected == "territory_id") {
				this.clearFilter("region_id");
				this.clearFilter("agency_id");

				//REGIONS
				this.setRegionId(null);
				this.setRegionFilter(this.filters.territory_id.selected);
				this.regionActions("getRegions")
					.then((response) => {
						if (response) {
							if (this.readRegions.length > 0) {
								for (let r in this.readRegions) {
									this.filters.region_id.options[
										this.readRegions[r].id
									] = this.readRegions[r].name;
								}
							}
						}
					})
					.finally(() => {
						this.setLoading(false);
					});
			} else if (selected == "region_id") {
				this.clearFilter("agency_id");

				//AGENCIES
				this.setAgencyId(null);
				this.setAgencyFilter(this.filters.region_id.selected);
				this.agencyActions("getAgencies")
					.then((response) => {
						if (response) {
							if (this.readAgencies.length > 0) {
								for (let a in this.readAgencies) {
									this.filters.agency_id.options[
										this.readAgencies[a].id
									] = this.readAgencies[a].name;
								}
							}
						}
					})
					.finally(() => {
						this.setLoading(false);
					});
			} else {
				this.setLoading(false);
			}
		},
		remove(user) {
			this.listed = false;
			this.setLoading(true);
			this.$swal({
				title: "¿Desea eliminar este Colaborador?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.setDeleteId(user.id);
					this.deleteUser().then((response) => {
						if (response) {
							this.loadUsers();
							this.$swal({
								icon: "success",
								title: `El Colaborador ${
									!user.name || user.basic_info
										? `${user.first_name} ${user.last_name}`
										: user.name
								}  ha sido eliminado correctamente.`,
								showConfirmButton: false,
								timer: 3000,
							});
						}
					});
				}
			});
			this.setLoading(false);
			this.listed = true;
		},
		clearFilter(filter) {
			this.filters[filter].selected = null;
			this.filters[filter].options = {};
		},
		clearErrors() {
			this.setUsersError(null);
			this.setDeleteUserError(null);
			this.setTerritoriesError(null);
			this.setRegionsError(null);
			this.setAgenciesError(null);
		},
	},
};
</script>

<template>
	<div class="main">
		<form
			@submit.stop.prevent="loadUsers"
			class="flex justify-evenly items-center md:justify-between flex-wrap bg-gray px-6 m py-10 pb-10 shadow-sm"
		>
			<h1 class="w-full text-center b text-xl m-auto mb-6">
				Lista de Colaboradores filtrados por:
			</h1>
			<label
				:for="key"
				v-for="(f, key) in filters"
				:key="key"
				class="w-full md:w-1/4 lg:w-1/5 flex flex-col justify-between md:h-20 mt-3 md:m-auto lg:m-0 lg:mr-4 text-lg"
			>
				<span class="w-full">{{ f.name }}</span>
				<select
					@change="refreshFilter(key)"
					class="w-full h-12 bg-white rounded-md px-4 py-2"
					v-model="f.selected"
					name="filter"
					:id="key"
					required
				>
					<option disabled value="null">
						Seleccionar {{ f.name }}
					</option>
					<option v-for="(v, i) in f.options" :key="i" :value="i">
						{{ v }}
					</option>
				</select>
			</label>

			<div
				class="w-full lg:w-1/5 md:h-20 flex justify-center items-end mt-6 md:mt-0"
			>
				<input
					type="submit"
					value="Buscar"
					class="cursor-pointer w-96 h-14 px-4 py-2 text-xl bg-orange text-white b rounded-3xl border-4 border-solid shadow-lg"
				/>
			</div>
			<div class="w-full">
				<span
					v-if="getUsersError"
					class="block my-5 red text-center b"
					>{{ getUsersError }}</span
				>
				<span
					v-if="deleteUserError"
					class="block my-5 red text-center b"
					>{{ deleteUserError }}</span
				>
				<span
					v-if="territoriesError"
					class="block my-5 red text-center b"
					>{{ territoriesError }}</span
				>
				<span
					v-if="regionsError"
					class="block my-5 red text-center b"
					>{{ regionsError }}</span
				>
				<span
					v-if="agenciesError"
					class="block my-5 red text-center b"
					>{{ agenciesError }}</span
				>
			</div>
		</form>
		<div v-if="!isLoading && users.length > 0" id="no-more-tables">
			<table class="block lg:table table-auto w-full">
				<thead class="block lg:table-header-group bg-green-dark">
					<tr class="block lg:table-row border border-gray">
						<th
							class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
							v-for="(field, key) in fields"
							:key="key"
						>
							{{ field.name }}
						</th>
						<th
							class="block lg:table-cell b text-white border-r py-3 px-1"
						>
							Acciones
						</th>
					</tr>
				</thead>
				<tbody class="block md:flex flex-wrap lg:table-row-group">
					<tr
						class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
						:class="
							users.length > 1
								? 'w-full md:w-1/2 lg:w-full'
								: 'w-full'
						"
						v-for="(user, i) in users"
						:key="i"
					>
						<td
							class="border-r text-center py-3 px-1"
							:class="{ 'green b': key == 'id' }"
							v-for="(field, key) in fields"
							:key="key"
							v-show="key != 'password'"
						>
							{{ user[key] }}
						</td>
						<td
							class="flex justify-evenly px-1 items-center py-3 icons lg:table-cell"
						>
							<div
								class="w-full flex justify-evenly items-center"
							>
								<span
									class="title-hint"
									aria-label="Da clic aquí para editar este Colaborador"
								>
									<font-awesome-icon
										@click="
											$router.replace({
												name: 'InfoColaborador',
												params: { id: user.id },
											})
										"
										class="green hover:text-white text-3xl md:text-2xl mr-3"
										icon="fa-solid fa-pen-to-square"
									/>
								</span>
								<span
									class="title-hint"
									aria-label="Da clic aquí para eliminar este Colaborador"
								>
									<font-awesome-icon
										@click="remove(user)"
										class="red hover:text-white text-3xl md:text-2xl cursor-pointer"
										icon="fa-solid fa-trash-can"
									/>
								</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron usuarios</span
		>
	</div>
</template>

<style lang="scss" scoped>
/*
	Label the data
	*/

@media (max-width: 1023px) {
	$fields: 1 "id", 2 "Número", 3 "Nombres", 4 "Apellidos", 5 "Email",
		6 "Tiempo Completo", 7 "Activo", 8 "Puesto";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i}):before {
			content: $field;
		}
	}
}
</style>
